import { Swiper } from "swiper";
import { Navigation } from "swiper/modules";

new Swiper('#portfolio-home .swiper', {
  modules: [Navigation],
  slidesPerView: 1.6,
  spaceBetween: 18,

  navigation: {
    prevEl: '#portfolio-home .swiper-button-prev',
    nextEl: '#portfolio-home .swiper-button-next',
  },

  breakpoints: {
    540: {
      slidesPerView: 2,
      spaceBetween: 24,
    },

    800: {
      slidesPerView: 3,
    },

    1200: {
      slidesPerView: 4,
    },
  },
});