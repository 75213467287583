import { Swiper } from "swiper";
import { Thumbs, EffectFade, Navigation } from "swiper/modules";

const thumb = new Swiper('.gallery__thumb .swiper', {
  slidesPerView: 3,
  spaceBetween: 12,
  slideToClickedSlide: true,
});

new Swiper('.gallery__main .swiper', {
  modules: [Thumbs, EffectFade, Navigation],

  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },

  slidesPerView: 1,
  spaceBetween: 24,

  thumbs: {
    swiper: thumb,
  },

  navigation: {
    prevEl: ".gallery__thumb .swiper-button-prev",
    nextEl: ".gallery__thumb .swiper-button-next"
  }
});

