import MicroModal from "micromodal";
import LazyLoad from "vanilla-lazyload";

import { modalParams } from "../base/settings";

export const lazyLoadInstance = new LazyLoad({
  elements_selector: ".lazy__item",

  callback_loaded: (trigger) => {
    const container = trigger.closest(".lazy");
    const loader = container.querySelector(".preloader");

    if (loader) loader.classList.add("preloader--hide");
  },
});

new LazyLoad({
  elements_selector: '.lazy-bg',
});

MicroModal.init(modalParams);