import { Swiper } from "swiper";
import { Navigation } from "swiper/modules";

new Swiper('#product-slider .swiper', {
  modules: [Navigation],
  slidesPerView: 1.4,
  spaceBetween: 20,

  breakpoints: {
    540: {
      slidesPerView: 2,
      spaceBetween: 24,
    },

    800: {
      slidesPerView: 3,
    },

    1200: {
      slidesPerView: 5,
    },
  },

  navigation: {
    prevEl: '#product-slider .swiper-button-prev',
    nextEl: '#product-slider .swiper-button-next',
  }
});