import { StyleСlass } from "../base/settings";

const mobile = document.querySelector('.mobile-menu');
const wrapper = mobile.querySelector('.mobile-menu__wrapper');
const nav = wrapper.querySelector('nav');

if (wrapper) {
  window.addEventListener('dropdownToggle', () => {
    const hasVerticalScrollbar = nav.scrollHeight > nav.clientHeight;

    if (hasVerticalScrollbar) wrapper.classList.add('mobile-menu__wrapper--scrollable');
    else wrapper.classList.remove('mobile-menu__wrapper--scrollable');
  });
}

window.addEventListener('click', (e) => {
  const target = e.target;
  if (target.closest('.header__burger')) {
    mobile.classList.toggle(StyleСlass.mobile.open);
  }

  else if (target.closest('.mobile-menu__overlay'))
    mobile.classList.remove(StyleСlass.mobile.open);

  else if (target.closest('.mobile-menu__close'))
    mobile.classList.remove(StyleСlass.mobile.open);
});